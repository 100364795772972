import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/theme/layout'
import Seo from '../../components/seo'

import './legal.scss'

export default function CookiesAndAdvertising() {
    return (
        <Layout>
            <Seo title='Cookies policy' />
            <div className='legal-container'>
                <h1>Cookies and Advertising Policy</h1>

                <p>Thank you for visiting! The Qiwio website, application and its online services (collectively <strong>“Site”</strong>) are provided by Qiwio AB (<strong>“Qiwio”</strong>). This Cookies and Advertising Policy explains how
                information is collected about your online browsing activity, including when you interact with our services, and how it may be used (<strong>“Policy”</strong>).</p>

                <p>In this Policy, the words “our”, “we” or “us” refer to Qiwio and any of its affiliates that provide our services. The words “you” or “your” means the user of the Site.</p>

                <h2>Learn About Cookies and Advertising</h2>

                <h3>What are cookies?</h3>
                <p>Like lots of websites, we use <strong>“cookies”</strong> and other similar tracking technologies (such as pixel tags, scripts, etags and web beacons) on our Site. A cookie is a small text file installed on your computer or other device
                that enables the Site to recognize you. It consists of a unique number and can distinguish you from other visitors. It does not contain any personal details. This means that it is very difficult to identify you personally
                on the basis of such cookies, or identify you when you visit third party websites.</p>

                <h3>What Is Interest-based Advertising?</h3>
                <p>Interest-based advertising means programs that decide which ads you might like to see based on information from your online activities, such as the actions you take when you visit this Site and other websites. This information helps
                understand your interests in order to display ads that you might enjoy.</p>

                <h3>What Is Customized Social Media Advertising?</h3>
                <p>Customized social media advertising means third party social platforms such as Facebook are used to show you customized ads. Your email address, telephone number or other contact information you have provided is converted into a unique
                value which can be matched to your profile by the social media platform, if you have provided that information to the partner of the social media platform. For information on Facebook’s advertising privacy policies please visit:
                <a href="https://www.facebook.com/about/privacy/" target='_blank' rel="noreferrer">https://www.facebook.com/about/privacy/</a>.</p>


                <h2>How Qiwio Uses Cookies and Advertising</h2>

                <h3>Cookies</h3>
                <p>We use two types of cookies on our Site – persistent and session cookies. Persistent cookies remain on your device after you leave our Site while session cookies are temporary and are deleted from your device after you close your browser.
                Depending on the type, the cookies may be necessary for the Site to function, or improve performance or assist us in recognizing you, such as when you log into your account. We collect this data to help us analyze our Site flow, performance,
                content structure, better reflect visitor preferences, prepopulate information so that you do not have to enter it over and over again, track usage and tailor advertisements to you. Our third party service management providers use third
                party cookies or other digital markers to help us maintain and improve the Site, and deliver interest-based advertising on the Site and on other websites. Finally, cookies may facilitate certain third party applications such as social
                media network connections. The bottom of this policy sets out the cookies used by Qiwio.</p>

                <h3>Advertising</h3>
                <p>We continuously improve our Site and use interest-based advertising services because we believe that advertising tailored to your particular interests is more helpful and relevant to you. We personalize the Site and participate in
                network advertising programs that display advertising about Qiwio on other unaffiliated websites.</p>
                <p>We use third party social media platforms (e.g. Facebook, etc.) to show you customized social media ads.</p>


                <h2>Your choices</h2>

                <p>You have choices when it comes to cookies and advertising.</p>
                <p><strong>Cookies.</strong> Most browsers are set up to accept cookies; however, the “help” feature of the menu bar on most browsers will explain how you can stop accepting cookies and how to turn off existing cookies.</p>
                <p><strong>Interest-Based Advertising.</strong> You can opt-out of network advertising programs that track your activities across multiple websites to deliver personalized advertising content to you.</p>
                <p>Please note that if you opt-out you may still receive online advertising from Qiwio on other websites or applications; however, these advertisements will not be displayed to you based on your interests. Please also note
                that deleting browser cookies can remove your opt-out preferences.</p>
                <p><strong>Customized Social Media Ads.</strong> To opt-out of customized social media ads, adjust your social advertising settings (for example, through the settings of your Facebook profile), or use the links provided in the ad itself.</p>

                <h3>Questions</h3>
                <p>Questions or complaints about this Policy or Qiwio’s practices? You may contact us directly as follows:</p>
                <p><strong>Email:</strong> <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link></p>
                <p><strong>Mail:</strong> Qiwio AB, Kristinebergsvägen 22, 302 41 Halmstad, Sweden</p>

                <p><strong>Last updated: May 24, 2022</strong></p>
            </div>
        </Layout>
    )
}